<template>
  <div class="ud-body">
    <div class="real-time-monitoring">
      <a-card :bordered="true">
        <a-row class="info-box">
          <a-col :span="6">
            <div>换电柜编号: {{ cabinetStateInfo.cabinetCode }}</div>
            <div>换电柜名称: {{ cabinetStateInfo.cabinetName }}</div>
            <div>
              换电柜状态:
              {{
                cabinetStateInfo.cabSta === '0'
                  ? '初始化'
                  : cabinetStateInfo.cabSta === '1'
                  ? '空闲'
                  : cabinetStateInfo.cabSta === '2'
                  ? '换电'
                  : cabinetStateInfo.cabSta === '3'
                  ? '还电'
                  : cabinetStateInfo.cabSta === '4'
                  ? '取电'
                  : cabinetStateInfo.cabSta === '5'
                  ? '异常'
                  : cabinetStateInfo.cabSta === '6'
                  ? '禁用'
                  : ''
              }}
            </div>
          </a-col>
          <a-col :span="6">
            <div>换电柜经度: {{ cabinetStateInfo.longitude }}</div>
            <div>换电柜纬度: {{ cabinetStateInfo.latitude }}</div>
            <div>换电柜总用电量: {{ cabinetStateInfo.emKwh || 0 }}kWh</div>
          </a-col>
          <a-col :span="6">
            <div>GSM小区信息: {{ cabinetStateInfo.locationSta }}</div>
            <div>GSM信号强度: {{ cabinetStateInfo.dBM }}</div>
            <div>
              换电柜是否禁用:
              {{
                cabinetStateInfo.cabEnable === '1'
                  ? '启用'
                  : cabinetStateInfo.cabEnable === '0'
                  ? '禁用'
                  : ''
              }}
            </div>
          </a-col>
          <a-col :span="6">
            <div>换电柜总电压: {{ cabinetStateInfo.cabVol || 0 }}V</div>
            <div>换电柜总电流: {{ cabinetStateInfo.cabCur || 0 }}A</div>
            <div>柜体温度值: {{ cabinetStateInfo.cabT || 0 }}&#8451;</div>
          </a-col>
        </a-row>
      </a-card>
      <a-tabs
        v-model:activeKey="activeKey"
        :tabBarStyle="tabBarSty"
        @change="change"
      >
        <!-- 换电柜门 -->
        <a-tab-pane key="1" tab="换电柜门" class="charge-container">
          <div
            class="charge-cabinet-box"
            :style="`${item.boxSta === '0' ? 'border:1px solid grey' : ''}`"
            v-for="(item, index) in cabinetBoxList"
            :key="index"
          >
            <div class="charge-code" v-if="item.boxSta !== '0'">
              {{ item.batteryId }}
            </div>
            <div
              class="charge-battery-box"
              :style="`${item.boxSta === '0' ? 'margin-top:40px' : ''}`"
            >
              <battery
                :num="item.batterySoc"
                :backgrounds="`${item.boxSta === '0' ? 'grey' : '#0060aa'}`"
                :state="item.boxSta"
                :isShowAnimation="true"
              />
              <div class="charge-btn">
                <div
                  class="btn-1"
                  :style="`${
                    item.boxSta === '0'
                      ? 'border:1px solid grey;color:black;'
                      : ''
                  }`"
                  @click="visiblePerformance(item.doorId)"
                >
                  实时性能
                </div>
                <div
                  class="btn-2"
                  :style="`${
                    item.boxSta === '0'
                      ? 'border:1px solid grey;color:black;'
                      : ''
                  }`"
                  @click="visibleChargeCabinet(item)"
                >
                  柜门操作
                </div>
              </div>
            </div>
            <div
              class="charge-cabinet-state"
              :style="`${
                item.boxSta === '0' ? 'border-top:1px solid grey' : ''
              }`"
            >
              {{
                item.boxSta === '2'
                  ? '电池充满'
                  : item.boxSta === '0'
                  ? '空仓'
                  : item.boxSta === '1'
                  ? '电池正在充电'
                  : item.boxSta === '5'
                  ? '异常'
                  : item.boxSta === '6'
                  ? '有电池未识别编号'
                  : item.boxSta === '7'
                  ? '待充电'
                  : ''
              }}
            </div>
            <div
              class="charge-caninet-num"
              :style="`${item.boxSta === '0' ? 'background-color:grey' : ''}`"
            >
              {{ item.doorId }}
            </div>
          </div>
        </a-tab-pane>
        <!-- 告警 -->
        <a-tab-pane key="2" tab="告警">
          <a-card>
            <ud-pro-table
              ref="table"
              row-key="id"
              :datasource="datasource"
              :columns="columns"
              :scroll="{ x: 'max-content' }"
            >
            </ud-pro-table>
          </a-card>
        </a-tab-pane>
        <!-- 日志 -->
        <a-tab-pane key="3" tab="日志">
          <a-card>
            <ud-pro-table
              ref="tables"
              row-key="id"
              :datasource="datasources"
              :columns="columnss"
              :scroll="{ x: 'max-content' }"
            ></ud-pro-table>
          </a-card>
        </a-tab-pane>
        <template #tabBarExtraContent>
          <a-button class="export-btn">
            <icon-font type="icon-daochu"></icon-font>
            导出
          </a-button>
        </template>
      </a-tabs>
      <!-- 实时性能对话框 -->
      <a-modal
        v-model:visible="performanceVisible"
        :width="1000"
        :centered="true"
        :footer="null"
        title="NO.1柜门实时性能"
        class="modal-sty"
      >
        <a-row class="modal-info-box">
          <a-col :span="6">
            <div>柜体状态:{{}}</div>
            <div>电池故障:{{ batteryInfo.bmsFault || '' }}</div>
            <div>SOH:{{ batteryInfo.soh || 0 }}%</div>
          </a-col>
          <a-col :span="8">
            <div>柜内电池编码:{{ batteryInfo.batteryId }}</div>
            <div>电池警告:{{ batteryInfo.bmsAlarm }}</div>
            <div>BMS板温度值:{{ batteryInfo.bmsT }}&#8451;</div>
          </a-col>
          <a-col :span="6">
            <div>电池充电时长:{{ batteryInfo.batchgTime }}分钟</div>
            <div>柜门状态:{{}}</div>
            <div>电芯温度值:{{ batteryInfo.batT }}&#8451;</div>
          </a-col>
          <a-col :span="4">
            <div>充电电流:{{ batteryInfo.chgCur }}A</div>
            <div>SOC:{{ batteryInfo.soc }}%</div>
          </a-col>
        </a-row>
        <div class="current-time">{{ new Date().toLocaleString('zh') }}</div>
        <div class="vdc-list">
          <div v-for="(item, index) in vdcList" :key="index" class="vdc-item">
            <span class="vdc-index">{{ index + 1 }}.</span>
            <battery :vdc="item" name="vdc" backgrounds="#001f38" />
          </div>
        </div>
      </a-modal>
      <!-- 柜门操作对话框 -->
      <a-modal
        v-model:visible="chargeCabinetVisible"
        :width="800"
        :centered="true"
        :footer="null"
        title="NO.1柜门操作"
        class="modal-sty"
      >
        <div class="operation">
          <div class="operation-btn" @click="operation(1)">开门</div>
          <div class="operation-btn" @click="operation(2)">禁用柜门</div>
          <div class="operation-btn" @click="operation(3)">启用柜门</div>
          <!-- <div class="operation-btn">绑定电池</div>
          <div class="operation-btn">解绑电池</div> -->
        </div>
      </a-modal>
      <!-- 柜门操作对话框 -->
      <a-modal
        v-model:visible="show"
        :width="500"
        :centered="true"
        @ok="confirm"
        @cancel="cancel"
        :title="title"
        class="modal-sty"
      >
        <a-form :model="form" :rules="rules" ref="from">
          <a-form-item label="操作密码" name="password">
            <a-input-password
              v-model:value="form.password"
              placeholder="请输入操作密码"
            />
          </a-form-item>
          <a-form-item label="禁用原因" name="remark" v-if="form.type === 2">
            <a-select
              v-model:value="form.remark"
              placeholder="请选禁用原因"
              allow-clear
            >
              <a-select-option
                v-for="item in reasonList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import battery from '@/components/battery/index.vue'
import * as cabinetApi from '@/api/rnt/chargeCabinet'
import * as dictDataApi from '@/api/dictData/index'
import { ref } from 'vue'
import { createFromIconfontCN } from '@ant-design/icons-vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2925620_dbzubnkko0e.js'
})
export default {
  name: 'realTimeMonitoring',
  data() {
    return {
      cabinetCode: null,
      isChange: [false, false, false],
      form: {},
      rules: {
        password: [
          {
            required: true,
            type: 'string',
            trigger: 'blur',
            message: '请输入操作密码'
          }
        ],
        remark: [
          {
            required: true,
            type: 'string',
            trigger: 'blur',
            message: '请选择禁用原因'
          }
        ]
      },
      title: '',
      reasonList: [],
      cabinetStateInfo: {},
      cabinetBoxList: [],
      show: false, // 柜门操作对话框显示隐藏
      tabBarSty: {
        backgroundColor: 'white',
        padding: '0px 40px'
      },
      // 换电柜门状态
      activeKey: ref('1'), // 激活当前面板
      performanceVisible: false, // 柜门实时性能对话框显示与隐藏
      batteryInfo: {},
      vdcList: [],
      chargeCabinetVisible: false, // 柜门操作对话框显示与隐藏
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 100,
          customRender: ({ index }) => index + 1,
          align: 'center'
        },
        {
          title: '告警名称',
          dataIndex: 'alarmDescName',
          align: 'center'
        },
        {
          title: '电池编号',
          dataIndex: 'batteryId',
          align: 'center'
        },
        {
          title: '仓门号',
          dataIndex: 'doorId',
          align: 'center'
        },
        {
          title: '告警类型',
          dataIndex: 'alarmTypeName',
          align: 'center'
        },
        {
          title: '告警等级',
          dataIndex: 'alarmLevel',
          align: 'center'
        },
        {
          title: '告警开始时间',
          dataIndex: 'alarmTime',
          align: 'center'
        },
        {
          title: '告警结束时间',
          dataIndex: 'alarmEndTime',
          align: 'center'
        }
      ],
      columnss: [
        {
          title: '序号',
          key: 'index',
          width: 100,
          customRender: ({ index }) => index + 1,
          align: 'center'
        },
        {
          title: '操作内容',
          dataIndex: 'content',
          width: 500,
          align: 'center'
        },
        {
          title: '用户',
          dataIndex: 'username',
          align: 'center'
        },
        {
          title: '操作时间',
          dataIndex: 'createTime',
          align: 'center'
        }
      ]
    }
  },
  created() {
    if (this.$route.query.cabinetCode) {
      this.cabinetCode = this.$route.query.cabinetCode
      this.getCabinetState()
      this.getCabinetBox()
    }
    this.getReasonList()
  },
  watch: {
    $route: 'getQuery',
    cabinetCode() {
      this.isChange = [false, false, false]
      this.activeKey = '1'
      this.getCabinetState()
      this.init()
    }
  },
  components: {
    battery,
    IconFont
  },
  methods: {
    // 获取路由参数
    getQuery() {
      if (this.$route.query.cabinetCode) {
        this.cabinetCode = this.$route.query.cabinetCode
      }
    },
    init() {
      this.$nextTick(() => {
        if (this.activeKey === '1') {
          if (!this.isChange[0]) {
            this.isChange[0] = true
            this.getCabinetBox()
          }
        }
        if (this.activeKey === '2') {
          if (!this.isChange[1]) {
            this.isChange[1] = true
            this.$refs.table.reload({ page: 1 })
          }
        }
        if (this.activeKey === '3') {
          if (!this.isChange[2]) {
            this.isChange[2] = true
            this.$refs.tables.reload({ page: 1 })
          }
        }
      })
    },
    // 获取禁用原因list
    getReasonList() {
      dictDataApi
        .getDictData({
          dictCode: 'enableReason'
        })
        .then((res) => {
          if (res.code === 0) {
            this.reasonList = res.data
          } else {
            this.$error(res.msg)
          }
        })
        .catch((e) => {
          this.$error(e.message)
        })
    },
    cancel() {
      this.show = false
    },
    // 柜门操作确认按钮
    confirm() {
      const that = this
      this.$refs.from
        .validate()
        .then(() => {
          if (this.form.type === 1) {
            cabinetApi
              .openDoor(this.form)
              .then((res) => {
                if (res.code === 0) {
                  this.show = false
                } else {
                  this.$message.error(res.msg)
                }
              })
              .catch((e) => {
                this.$message.erorr(e.message)
              })
          } else if (this.form.type === 2) {
            this.form.enable = 0
            this.show = false
            cabinetApi
              .enabledoor(this.form)
              .then((res) => {
                if (res.code === 0) {
                  this.show = false
                  setTimeout(() => {
                    that.getCabinetBox()
                  }, 2000)
                } else {
                  this.$message.error(res.msg)
                }
              })
              .catch((e) => {
                this.$message.erorr(e.message)
              })
          } else if (this.form.type === 3) {
            this.form.enable = 1
            this.show = false
            cabinetApi
              .enabledoor(this.form)
              .then((res) => {
                if (res.code === 0) {
                  this.show = false
                  setTimeout(() => {
                    that.getCabinetBox()
                  }, 2000)
                } else {
                  this.$message.error(res.msg)
                }
              })
              .catch((e) => {
                this.$message.erorr(e.message)
              })
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 柜门操作 // 开门 // 禁用仓门 //启用仓门
    operation(type) {
      this.show = true
      this.form.password = ''
      this.form.remark = ''
      if (type === 1) {
        this.title = '开仓检查'
        this.form.type = 1
      } else if (type === 2) {
        this.title = '禁用仓门'
        this.form.type = 2
      } else if (type === 3) {
        this.title = '启用仓门'
        this.form.type = 3
      }
    },
    // 获取换电柜操作日志信息
    datasources(option, callback) {
      cabinetApi
        .cabinetRecord({
          sort: 'createTime',
          order: 'desc',
          cabinetCode: this.cabinetCode,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    // 获取换电柜告警信息
    datasource(option, callback) {
      cabinetApi
        .cabinetAlarm({
          sort: 'createTime',
          order: 'desc',
          cabinetCode: this.cabinetCode,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    // 获取换电柜仓门状态
    getCabinetBox() {
      cabinetApi
        .cabinetBox({
          cabinetCode: this.cabinetCode,
          sort: 'doorId',
          order: 'asc'
        })
        .then((res) => {
          if (res.code === 0) {
            this.cabinetBoxList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取换电柜运行状态
    getCabinetState() {
      cabinetApi
        .cabinetState({ cabinetCode: this.cabinetCode })
        .then((res) => {
          if (res.code === 0) {
            this.cabinetStateInfo = res.data[0]
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查看换电柜门实时性能
    visiblePerformance(doorId) {
      this.performanceVisible = true
      cabinetApi
        .cabinetBat({ doorId, cabinetCode: this.cabinetCode })
        .then((res) => {
          if (res.code === 0) {
            if (res.data.length > 0) {
              this.batteryInfo = res.data[0]
              if (res.data[0].cellVol) {
                this.vdcList = JSON.parse(res.data[0].cellVol)
              }
            } else {
              this.batteryInfo = {}
              this.vdcList = []
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查看柜门柜门操作
    visibleChargeCabinet(item) {
      this.chargeCabinetVisible = true
      this.form.cabinetCode = item.cabinetCode
      this.form.doorId = item.doorId
    },
    change() {
      this.init()
    }
  }
}
</script>

<style lang='less' scoped>
.export-btn {
  border-radius: 15px;
  background-color: #0060aa;
  color: white;
}
.operation {
  display: flex;
  flex-wrap: wrap;
  .operation-btn {
    width: 200px;
    padding: 10px 20px;
    border: 1px solid #0060aa;
    border-radius: 20px;
    background-color: #e6f0f7;
    color: #0060aa;
    margin-left: 50px;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
  }
}
.info-box {
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  div {
    margin-bottom: 5px;
  }
}
.vdc-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .vdc-item {
    margin-left: 35px;
  }
  .vdc-index {
    position: relative;
    left: -15px;
    top: 20px;
  }
}
.current-time {
  width: 200px;
  margin-left: 80%;
  padding: 3px 10px;
  background-color: #0060aa;
  text-align: center;
  border-radius: 20px;
  color: white;
}
.modal-info-box {
  background-color: white;
  // padding: 20px;
  margin: 10px 0;
  div {
    margin-bottom: 5px;
  }
}
.charge-container {
  display: flex;
  // justify-content: space-around;
  justify-content: flex-start;
  flex-wrap: wrap;
  .charge-cabinet-box {
    position: relative;
    background-color: white;
    width: 300px;
    padding: 10px 20px 0;
    border: 1px solid #0060aa;
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: 60px;
    .charge-code {
      color: #0060aa;
      text-decoration: underline;
    }
    .charge-battery-box {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .btn-1 {
        padding: 5px 15px;
        color: #0060aa;
        border: 1px solid #0060aa;
        border-radius: 20px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .btn-2 {
        padding: 5px 15px;
        color: #0060aa;
        border: 1px solid #0060aa;
        border-radius: 20px;
        cursor: pointer;
      }
    }
    .charge-cabinet-state {
      text-align: center;
      border-top: 2px solid #0060aa;
      margin-top: 20px;
      padding: 3px 0;
      color: grey;
    }
    .charge-caninet-num {
      background-color: #0060aa;
      text-align: center;
      color: white;
      font-size: 15px;
      width: 100px;
      padding: 4px 0;
      position: absolute;
      left: 230px;
      top: 10px;
      transform: rotate(45deg);
    }
  }
}
</style>
<style>
.modal-sty .ant-modal-header {
  background-color: #0060aa !important;
}
.modal-sty .ant-modal-title {
  color: white;
}
</style>
